.App {
  display: flex;
  justify-content: center;
  height: 100dvh;
  align-items: center;
  flex-direction: column;
  background-color: white;
}
.title {
  text-align: center;
  font-size: 18pt;
  font-weight: 400;
  font-family: "Libre Barcode 128 Text", system-ui;
}
.links {
  display: flex;
  flex-direction: row;
  text-align: center;
  font-size: 10pt;
}

.content {
  display: flex;
  flex-direction: column;
  text-align: center;
}

a {
  text-decoration: none;
  color: black;
  padding: 10pt 10pt 0pt 10pt;
}
.container {
  width: 50%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
a:hover {
  color: blue;
}

.text {
  text-align: center;
  font-style: italic;
  font-size: 10pt;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
